document.addEventListener("DOMContentLoaded", function () {

  let isMobile = window.innerWidth <= 446;

  function calculateDots(sliderContainer, partWidth, dotsContainer, count) {
    const currentPosition = sliderContainer.scrollLeft;
    sliderContainer
      .querySelectorAll(".project-feature-img")
      .forEach((item, index) => {
        let xMax = partWidth * index; //100%
        let xMin = xMax + partWidth; // 0%
        if (index === 0 && currentPosition > xMin) {
          dotsContainer.children[index].classList.remove("active");
          dotsContainer.children[index].style.width = 8 + "px";
          return;
        }
        if (index === count - 1 && currentPosition >= xMin) {
          dotsContainer.children[index].classList.add("active");
          dotsContainer.children[index].style.width = 50 + "px";
          return;
        }
        if (index > 0 && (currentPosition < xMax || currentPosition > xMin)) {
          dotsContainer.children[index].classList.remove("active");

          dotsContainer.children[index].style.width = 8 + "px";
          return;
        }

        if (index === 0) {
          const persent = 100 - (currentPosition * 100) / xMin;
          if (persent < 0) return;
          let newWidth = (100 * persent) / 100;
          if (newWidth < 8) newWidth = 8;
          dotsContainer.children[index].style.width = newWidth + "px";
          if (newWidth > 8) {
            dotsContainer.children[index].classList.add("active");
          } else {
            dotsContainer.children[index].classList.add("active");
          }
        } else {
          xMax = 0; //100%
          xMin = xMax + partWidth; // 0%
          let newCurrentPosition = currentPosition - partWidth * index;
          const newPersent = (newCurrentPosition * 100) / xMin;
          if (newPersent < 0) return;
          let newWidth2 = (100 * newPersent) / 100;
          if (newWidth2 < 8) newWidth2 = 8;
          dotsContainer.children[index].style.width = newWidth2 + "px";
          if (newWidth2 > 8) {
            dotsContainer.children[index].classList.add("active");
          } else {
            dotsContainer.children[index].classList.add("active");
          }
        }
      });
  }

  if (isMobile) {
    const dotsWrappers = document.querySelectorAll('[data-dots="with-dots"]');
    dotsWrappers?.forEach((wrapper) => {
      const sliderContainer = wrapper.querySelector(
        ".project-feature-img__grid"
      );
      const count =
        sliderContainer?.querySelectorAll(".project-feature-img")?.length || 0;
      if (!count) return;

      // Create dots container
      const dotsContainer = document.createElement("div");
      dotsContainer.className = "dots-container";
      for (let index = 0; index < count; index++) {
        const dot = document.createElement("span");
        dot.className = "dot";
        dotsContainer.appendChild(dot);
      }

      wrapper.appendChild(dotsContainer);

      const fullWidth =
        sliderContainer.scrollWidth - sliderContainer.clientWidth;
      const partWidth = fullWidth / count;

      calculateDots(sliderContainer, partWidth, dotsContainer, count);

      sliderContainer.addEventListener("scroll", function () {
        isMobile = window.innerWidth <= 446;

        if (!isMobile) return;
        calculateDots(sliderContainer, partWidth, dotsContainer, count);
      });
    });
  }
});
